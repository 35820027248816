@mixin errorTextDefaultStyle() {
  font-size: var(--font-size-2);
  line-height: 19px;
  color: var(--tunein-coral);
}

.link {
  height: 22px;

  span {
    font-size: var(--font-size-3);
  }

  &.isPartnerFlow {
    display: inline-block;
  }

  &:not(.isPartnerFlow) {
    display: block;
    text-align: center;
    margin-bottom: 22px;

    span {
      font-weight: bold;
      text-transform: uppercase;
      font-size: var(--font-size-1);
      line-height: 16px;
      letter-spacing: var(--letter-spacing-loose);
    }

    &.needHelpInlineLink {
      margin-left: 3px;
      margin-bottom: 0;

      > span {
        text-transform: lowercase;
      }
    }

    &.generalNoticeInlineLink {
      margin-bottom: 0;
      height: 19px;
      display: inline-block;
    }
  }
}

.description {
  text-align: left;
}

.finePrintStyle {
  margin-top: 15px;
}

.containerPadding {
  padding-right: 21px;
  padding-left: 21px;

  @media (--min-width-medium) {
    padding-right: 60px;
    padding-left: 60px;
  }
}

.containerRestrictions {
  margin-right: auto;
  margin-left: auto;
}

.authButton {
  height: 48px;
  width: 100%;
  border-radius: 54px;
  margin-bottom: 26px;

  &.isPartnerFlow {
    margin: 20px 0;
    height: 40px;
    border-radius: 8px;
    font-weight: 900;
  }

  &:not(.isPartnerFlow) {
    span {
      font-size: var(--font-size-1);
    }
  }
}

.nextButtonLabel {
  font-size: var(--font-size-5) !important;

  & > span {
    height: 20px;
    line-height: 18px;
  }
}

.nextButtonIcon {
  fill: var(--white);
  width: 18px;
  margin-left: 12px;
}

.forgotPasswordButton {
  width: 100%;
  height: 48px;
}

.withFormSections {
  
  form {
    .section {
      margin-bottom: 16px;

      &.noSpacing {
        margin-bottom: 0;
      }

      &.radioButtonsContainer {
        &:not(.isPartnerFlow) {
          margin-bottom: 30px;
        }
      }

      .fieldsSection {
        &.isPartnerFlow {
          margin-bottom: 22px;
        }

        .inputField {
          height: 48px !important;

          &.isPartnerFlow {
            input {
              &:autofill::first-line,
              &:autofill,
              &:autofill:hover,
              &:autofill:focus,
              &:autofill:active {
                font-family: inherit;
                background-color: var(--ink-11);
                caret-color: var(--off-white);
                -webkit-text-fill-color: var(--off-white);
                box-shadow: 0 0 0 1rem var(--ink-11) inset !important;
                filter: none; /* filter is a fireFox specific style */
              }
            }

            /* This is a hack to prevent overlapping of labels with autofilled input values on MUI Fields in WebKit browsers. */
            input:autofill + label {
              transform: scale(0.75) translate(0, -28px) !important;
            }
          }

          &:not(.isPartnerFlow) {
            margin-bottom: 15px;

            &.alongWithIcon {
              input {
                padding-right: 50px !important;
              }
            }
          }
        }
      }
    }

    .mediumSection {
      margin-bottom: 32px;

      .mediumSectionInner {
        justify-content: flex-start;
        display: flex;
        width: 100%;

        &.isPartnerFlow {
          justify-content: space-between;
        }

        .rememberMeCheckbox {
          width: 20px;
          height: 20px;

          &.isPartnerFlow {
            border: 1px solid var(--off-white) !important;
          }

          &:not(.isPartnerFlow) {
            background-color: transparent !important;
            border: none !important;
          }
        }

        .customCheckmarkClass {
          &.isPartnerFlow {
            width: 9px;
            height: 8px;
          }

          &:not(.isPartnerFlow) {
            width: 20px;
            height: 20px;
            background: transparent url('/assets/img/shared/checkbox-on.svg')
              no-repeat;
            background-size: auto !important;
            background-position: center !important;

            &.customCheckmarkUncheckedClass {
              opacity: 1 !important;
              background: transparent url('/assets/img/shared/checkbox-off.svg')
                no-repeat;
            }
          }
        }

        .rememberMeLabel {
          font-size: var(--font-size-1);

          &:not(.isPartnerFlow) {
            line-height: 16px;
          }
        }

        .forgotPasswordLink {
          span {
            font-size: var(--font-size-1) !important;
          }

          &.isPartnerFlow {
            span {
              color: var(--off-white);
            }
          }
        }
      }
    }

    @media (--min-width-small) {
      .mediumSection {
        .mediumSectionInner {
          &.isPartnerFlow {
            justify-content: center;

            .rememberMeLabel {
              font-size: 15px;
            }
          }

          .customCheckmarkClass {
            &.isPartnerFlow {
              width: 12px;
              height: 11px;
            }
          }

          .forgotPasswordLink {
            span {
              font-size: 15px !important;
            }
          }
        }
      }
    }

    .customErrorListClass {
      &:not(.isPartnerFlow) {
        list-style: none;
        margin-left: 0;
        margin-bottom: 20px;

        .customErrorTextClass {
          @include errorTextDefaultStyle;
        }
      }
    }

    .baseErrorClassName {
      @include errorTextDefaultStyle;

      margin-bottom: 10px;
    }
  }

  &.fullScreen {
    form {
      .section {
        @media (--min-width-medium) {
          margin-bottom: 20px;

          &.noSpacing {
            margin-bottom: 0;
          }
        }
      }

      .mediumSection {
        @media (--min-width-medium) {
          margin-bottom: 28px;
        }
      }

      .largeSection {
        @media (--min-width-medium) {
          margin-bottom: 36px;

          &.noSpacing {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.checkmark {
  position: absolute;
  right: 6px;
  top: 4px;
}

.errorText {
  color: var(--error-color);
}

.infoText {
  display: flex;
  justify-content: center;
  font-size: var(--font-size-1);
  line-height: 16px;
  }

.generalNoticeWrapper {
  margin-bottom: 29px;
}

.generalNoticeText {
  font-size: var(--font-size-2);
  line-height: 19px;
  text-align: center;
  color: var(--secondary-color-5);
}
