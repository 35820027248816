.authOptionsDivider {
  visibility: hidden;
  composes: verticalDivider from '../../styles/common.module.scss';
  margin-right: 4%;
  margin-left: 4%;

  @media (--min-width-small) {
    visibility: visible;
  }
}

.underlineLink {
  &.forgotPassword {
    &.isPartnerFlow {
      background-color: var(--off-white);
    }

    &:not(.isPartnerFlow) {
      background-color: transparent;
    }
  }
}
