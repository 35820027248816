.container {
  text-align: center;

  &:focus {
    outline: none;
  }
}

.partnerAuthContent {
  padding: 0 24px;
  background-color: var(--ink-11);

  @media (--min-width-medium) {
    padding: 0 115px;
    max-width: 1000px;
    width: 100%;
  }
}

.progressBarContainer {
  padding-bottom: 30px;

  @media (--min-width-medium) {
    padding-bottom: 45px;
    padding-top: 15px;
  }
}
