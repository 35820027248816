.showPasswordButton {
  position: absolute;
  top: 24px;
  right: 15px;
  transform: translateY(-50%);
  display: flex;
  padding: 0;
  border: none;
  background: none;

  .visibilityIcon {
    width: 30px;
    height: 30px;

    &.visibilityOffIcon {
      background: transparent url('/assets/img/shared/visibility-off.svg')
        no-repeat;
    }

    &.visibilityOnIcon {
      background: transparent url('/assets/img/shared/visibility-on.svg')
        no-repeat;
    }
  }
}
