.container {
  padding: 30px 0;
  text-align: center;
}

.localizedText {
  color: var(--secondary-color-5);
  font-weight: bold;
  font-size: 36px;
  line-height: 41px;
  text-align: center;
}

.successCheckmark {
  background: transparent url('/assets/img/shared/circle-checkmark-green.svg')
    center/contain no-repeat;
  height: 60px;
  width: 60px;
  margin: auto;
  margin-bottom: 10px;
}
