.dividerContainer {
  display: flex;
  margin-bottom: 24px;
}

.dividerBar {
  border-bottom: 1px solid var(--ink-med-dark);
  width: 39%;
  margin: auto 0;
}

.dividerText {
  &.isPartnerFlow {
    margin: auto;
    font-family: var(--auxiliary-font-header);
    padding-top: 1px;
    font-size: var(--font-size-3);
    font-weight: 500;
    line-height: 44px;
  }

  &:not(.isPartnerFlow) {
    margin-bottom: 11px;
    font-weight: bold;
    font-size: var(--font-size-1);
    line-height: 16px;
    letter-spacing: var(--letter-spacing-loose);
    text-transform: uppercase;
    text-align: center;
    color: var(--cloud-grey);
  }
}
