@mixin oAuthButton($bottomMargin, $width) {
  .oauthButton {
    margin-bottom: $bottomMargin;
    width: $width;
  }
}

.container {
  color: var(--secondary-color-5);
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;

  @include oAuthButton(0, 48%);

  &.threeButtonLayout {
    flex-direction: column;

    @include oAuthButton(12px, 100%);
  }

  &.isPartnerFlow {
    margin-bottom: 9px;
  }

  &:not(.isPartnerFlow) {
    margin-bottom: 19px;
  }

  &.smallBottomMargin {
    margin-bottom: 1.25rem;
  }

  &.fullScreen {
    &.isPartnerFlow {
      button {
        span {
          font-size: var(--font-size-2);
          font-weight: var(--font-weight-bold);
        }
      }
    }

    @media (--min-width-small) {
      &.threeButtonLayout {
        &.isPartnerFlow {
          flex-direction: column;

          @include oAuthButton(20px, 100%);
        }
      }

      &.paymentFlowLayout {
        &.threeButtonLayout {
          flex-direction: column;

          @include oAuthButton(11px, 100%);
        }
      }
    }

    @media (--min-width-xxxlarge) {
      &.paymentFlowLayout {
        &.threeButtonLayout {
          flex-direction: row;

          @include oAuthButton(0, 33%);
        }
      }
    }
  }
}
