.errorList {
  list-style: initial;
  text-align: left;
  margin-left: 15px;
}

.innerSection {
  margin-top: 20px;
}

.isPartnerFlow {
  font-size: var(--font-size-4);
  line-height: 18px;
  text-align: center;
  font-weight: 900;
}
