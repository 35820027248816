.secondaryContentOuter {
  text-align: center;
  margin-top: var(--default-margin-px);

  &.isPartnerFlow {
    text-align: left;
    padding-left: 0;
    padding-top: 12px;
  }

  &:not(.isPartnerFlow) {
    margin-top: 0;
    padding: 0;
    margin-bottom: 13px;
    font-size: var(--font-size-1);
    line-height: 16px;
    color: var(--secondary-color-5);
  }
}

.link {
  composes: inkLink from '../../../styles/common.module.scss';
  color: var(--secondary-color-5);
  display: inline-block;
  text-align: left;
  padding-left: var(--padding-small);
  text-decoration: underline;

  &.createAccount,
  &.signIn {
    &.isPartnerFlow {
      background-color: var(--off-white);
    }
  }

  &.isPartnerFlow {
    color: var(--off-white) !important;
    padding-left: 8px;
  }

  &:not(.isPartnerFlow) {
    padding-left: 8px;

    span {
      font-weight: bold;
    }
  }

  i {
    bottom: -1px;
    height: 1px;
  }

  &:hover {
    i {
      bottom: -3px;
    }
  }
}
