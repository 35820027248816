.authFlowPromoHeaderContainer {
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;

  @media (--min-width-medium) {
    margin-top: 50px;
    padding: 0 100px;
    max-width: 1000px;

    &.exaggerateMarginTop {
      margin-top: 115px;
    }
  }
}

.authFlowPromoHeaderLogoContainer {
  width: 100px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7px;

  img {
    width: 50px;
    height: 35px;

    @media (--min-width-medium) {
      width: 69px;
      height: 48px;
    }
  }
}

.authFlowPromoHeaderText {
  width: 60%;
  font-family: var(--auxiliary-font-header);
  font-size: 15px;
  height: 93px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;

  @media (--min-width-medium) {
    width: unset;
    font-size: var(--font-size-4);
  }
}
