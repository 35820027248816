.authFlowLayoutContainer {
  display: flex;
  justify-content: center;
  color: white;
  background-color: var(--ink-11);
  min-height: 100%;
  width: 100vw;
}

.authFlowLayoutContent {
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-direction: column;
  background-color: var(--ink-11);

  .authFlowLayoutFooter {
    padding: 0 24px;
    margin-bottom: 50px;
    display: flex;
    justify-content: left;

    @media (--min-width-medium) {
      padding: 0 115px;
      width: 1000px;
    }
  }
}

.authFlowLayoutSharedStyles {
  background-color: var(--ink-11);

  @media (--min-width-medium) {
    display: flex;
    justify-content: center;
  }
}
