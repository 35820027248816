.signUpOptions {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &:not(.isPartnerFlow) {
    flex-flow: row wrap;
  }
}

.signUpOption {
  margin-top: var(--default-margin-px);

  &:not(.isPartnerFlow) {
    width: 50%;

    @media (--min-width-medium) {
      width: auto;
      margin-top: 0;
    }
  }
}

.signUpOptionLabel {
  &.isPartnerFlow {
    color: var(--grey-6);
  }

  &:not(.isPartnerFlow) {
    font-size: var(--font-size-1);
    line-height: 16px;
    font-weight: 400;
    color: var(--secondary-color-5);

    .customSpanClassName {
      background: var(--grey-poupon);
    }
  }
}
