@mixin defaultButton() {
  height: var(--large-button-height);
  border-radius: 54px;

  span {
    font-size: var(--font-size-1);
    font-weight: bold;
    line-height: 16px;
    letter-spacing: var(--letter-spacing-loose);
    text-transform: uppercase;
    color: var(--secondary-color-5);
  }
}

@mixin partnerAuthButton() {
  height: var(--large-button-height);
  border-radius: 8px;
  background-color: var(--off-white);

  span {
    font-size: var(--font-size-3);
    color: var(--secondary-color-5);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: var(--grey-4);
  }
}

.googleButton {
  &.isPartnerFlow {
    @include partnerAuthButton;
  }

  &:not(.isPartnerFlow) {
    @include defaultButton;

    & {
      background-color: var(--grey-2);
    }

    &:hover,
    &:focus {
      border: 2px solid var(--grey-4);
      background-color: white;

      span {
        color: var(--secondary-color-5);
      }
    }

    &:active:focus {
      background-color: var(--grey-3);
    }
  }
}

.facebookButton {
  &.isPartnerFlow {
    @include partnerAuthButton;
  }

  &:not(.isPartnerFlow) {
    @include defaultButton;

    & {
      background-color: var(--facebook-color);
    }

    i svg {
      width: 20px;
    }

    &:hover,
    &:focus {
      background-color: var(--facebook-color-hover);
    }

    &:active:focus {
      background-color: var(--facebook-color-active);
    }
  }
}

.appleSignInButton {
  &.isPartnerFlow {
    @include partnerAuthButton;

    i {
      color: black;
      font-size: var(--font-size-5);
    }
  }

  &:not(.isPartnerFlow) {
    @include defaultButton;

    & {
      background-color: black;
      color: white;
    }

    i {
      color: black;
      font-size: var(--font-size-3);
    }

    &:hover,
    &:active,
    &:focus {
      border: black solid 2px;
      background-color: white;

      span {
        color: black;
      }
    }
  }
}

.googleButton,
.facebookButton,
.appleSignInButton {
  &:not(.isPartnerFlow) {
    border: 1px solid var(--elephant-grey);
    background-color: var(--content-area-background-color-hex);

    &:hover,
    &:focus {
      border: 2px solid var(--elephant-grey);
      background-color: var(--elephant-grey);
    }

    &:active:focus {
      border: 2px solid var(--grey-poupon);
      background-color: var(--grey-poupon);
    }
  }
}

.errorText {
  display: block;
  margin-top: 5px;
  padding: 0 5px;
  color: var(--error-color);
  font-size: var(--font-size-2);
}
