.container {
  text-align: center;

  &.isPartnerFlow {
    padding: 0 24px;
    margin-top: 20px;
    margin-bottom: 50px;
    background-color: var(--ink-11);

    @media (--min-width-medium) {
      padding: 0 115px;
      width: 1000px;
    }
  }

  .agreementLine {
    font-size: var(--font-size-1);
    text-align: center;

    &.isPartnerFlow {
      color: var(--grey-6);
      text-align: left;
      font-weight: 500;
      line-height: 14px;
    }

    &:not(.isPartnerFlow) {
      font-weight: 400;
      line-height: 16px;
      color: var(--secondary-color-5);
    }

    .legalLink {
      display: inline-block;
      height: 16px;

      &.serviceTerms,
      &.privacyPolicy {
        &.isPartnerFlow {
          background-color: var(--grey-6);
        }
      }

      &.isPartnerFlow {
        color: var(--grey-6);
      }

      &:not(.isPartnerFlow) {
        span {
          font-weight: bold;
        }
      }

      i {
        bottom: -1px;
        height: 1px;
      }

      &:hover,
      &:focus {
        i {
          bottom: -3px;
        }
      }
    }
  }
}
